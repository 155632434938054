// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-template-js": () => import("/opt/build/repo/src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-affiliate-js": () => import("/opt/build/repo/src/pages/affiliate.js" /* webpackChunkName: "component---src-pages-affiliate-js" */),
  "component---src-pages-bigcommerce-help-js": () => import("/opt/build/repo/src/pages/bigcommerce-help.js" /* webpackChunkName: "component---src-pages-bigcommerce-help-js" */),
  "component---src-pages-careers-js": () => import("/opt/build/repo/src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-design-js": () => import("/opt/build/repo/src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-development-js": () => import("/opt/build/repo/src/pages/development.js" /* webpackChunkName: "component---src-pages-development-js" */),
  "component---src-pages-docs-js": () => import("/opt/build/repo/src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-fullstackhq-reviews-js": () => import("/opt/build/repo/src/pages/fullstackhq-reviews.js" /* webpackChunkName: "component---src-pages-fullstackhq-reviews-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sample-preview-js": () => import("/opt/build/repo/src/pages/sample-preview.js" /* webpackChunkName: "component---src-pages-sample-preview-js" */),
  "component---src-pages-shopify-help-js": () => import("/opt/build/repo/src/pages/shopify-help.js" /* webpackChunkName: "component---src-pages-shopify-help-js" */),
  "component---src-pages-support-js": () => import("/opt/build/repo/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-thank-you-js": () => import("/opt/build/repo/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-woocommerce-help-js": () => import("/opt/build/repo/src/pages/woocommerce-help.js" /* webpackChunkName: "component---src-pages-woocommerce-help-js" */),
  "component---src-pages-wordpress-help-js": () => import("/opt/build/repo/src/pages/wordpress-help.js" /* webpackChunkName: "component---src-pages-wordpress-help-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

